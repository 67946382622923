import React from 'react'
import Link from '~/components/Link'
import { useLanguageSwitcher } from '~/hooks/useLanguageSwitcher'
import { cn } from '~/utils/cn'

export type LanguageSwitcherProps = PropsWithClassName<{
  LinkProps?: Omit<React.ComponentProps<typeof Link>, 'to' | 'onClick'>
  theme?: 'dark' | 'light'
}>

const LanguageSwitcher = ({
  className,
  LinkProps,
  theme = 'light'
}: LanguageSwitcherProps) => {
  const languages = useLanguageSwitcher()

  return (
    <div className={cn('flex gap-3', className)}>
      {languages.map((language) => {
        const { className: LinkClassName, ...restLinkProps } = LinkProps ?? {}

        return (
          <Link
            key={language.locale}
            to={language.to}
            testId={`language${language.locale.toUpperCase()}`}
            translate="no"
            // We reload the document to be sure the language is changed for axeptio
            reloadDocument
            aria-selected={language.isActive}
            className={cn(
              {
                'text-white/50 aria-selected:text-white': theme === 'light',
                'text-black/50 aria-selected:text-black': theme === 'dark'
              },
              LinkClassName
            )}
            underline="hover"
            {...restLinkProps}
          >
            {language.locale.toUpperCase()}
          </Link>
        )
      })}
    </div>
  )
}

export default LanguageSwitcher
